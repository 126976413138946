<template>
  <div class="qr-list">
    <v-data-table
      id="qrTable"
      :headers="headers"
      :items="desserts"
      sort-by="calories"
      class="elevation-1"

      :search="search"
    >
    <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }"  >
          {{$t(header.text)}}
    </template>
    <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
                {{translate(item[values.value])}}
    </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>QR</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title
            >{{translate(restaurantName)}}: {{id }}
            </v-toolbar-title
          >
           <!-- <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn small color="primary" @click="print()">Print</v-btn> -->
          <v-spacer></v-spacer>
          <v-text-field
            class="pr-4 pb-2"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            dense
          ></v-text-field>
        </v-toolbar>
        <v-toolbar flat>
          <v-toolbar-title>Card</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="card.width" label="Width" hide-details outlined dense style="width: 60px" class="mx-2" suffix="mm"></v-text-field>
          <v-text-field v-model="card.height" label="Height" hide-details outlined dense style="width: 60px" class="mx-2" suffix="mm"></v-text-field>
          <v-text-field
            v-model="card.marginTop"
            label="Margin Top"
            hide-details
            outlined
            dense
            style="width: 60px"
            class="mx-2"
            suffix="px"
            prepend-inner-icon="mdi-refresh"
            @click:prepend-inner="card.marginTop = 'auto'"
          >
          </v-text-field>
          <v-text-field
            v-model="card.fontSize"
            label="Font Size"
            hide-details
            outlined
            dense
            style="width: 60px"
            class="mx-2"
            suffix="px"
          >
          </v-text-field>
          <v-btn
            color="primary"
            dark
            class="mr-2"
            small
            @click="test"
            outlined
            style="height: 40px">
            <v-icon class="mr-2">mdi-printer</v-icon>{{ $t("print") }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.qrCode="{item}">
         <vue-qrcode
              :value="item.qrCode"
            ></vue-qrcode>
      </template>
      <template v-slot:item.type="{ item }">
        {{item.type=="customer"?translate("Радник"):translate("Фирма")}}
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> {{translate('Ресетујте')}} </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import axios from "../plugins/axios";
import conf from '../config'
export default {
  props: ['idProp'],
  components: {
    VueQrcode,
  },
  data: () => ({
    card: {
      width: '90',
      height: '50',
      marginTop: '25',
      fontSize: '13'
    },
    conf: conf,
    search: "",
    restaurantName: "",
    headers: [
      {
        text: "Rb",
        value: "rb"
      },
      {
        text: "Id",
        value: "id"
      },
      {
        text: "name",
        value: "name",
      },
      {
        text: "last_name",
        value: "lastname",
      },
      {
        text: "ek",
        value: "email",
      },
      {
        text: "role",
        value: "type",
      },
      { text: "qr_code", value: "qrCode",sortable: false},
    ],
    desserts: [],
    id: ''
  }),


  created() {
    if(this.$store.state.company){
      this.id = this.$store.state.company.id;
    } else {
      this.id = this.idProp;
    }
    if (this.$route.params.idProp) {
      this.id = this.$route.params.idProp;
    }

    if (this.$route.params.res) {
      this.id = this.$route.params.res
      this.initializeRes();
    } else if(this.$route.params.jela) {
        this.initializeJela();
    } else {
      this.initialize();
    }

  },

  methods: {
    test() {
      let isTable = this.desserts.every(i => i.company.type === 'table')
      let column = isTable ? 4 : 2

      let el = document.getElementById('qrTable');
      let trs = el.getElementsByTagName('tbody')
      let arr = trs[0].getElementsByTagName('tr')

      let html = '<table>'
      let i = 1
      arr.forEach((tr, index) => {

        if(index%column === 0) {
          i = 1
          html += '<tr>'
        }

        let tds = tr.getElementsByTagName('td')
          if(tds.length) {

            let id = tds[1].innerHTML
            let name = tds[2].innerHTML + ' ' + tds[3].innerHTML
            let email = tds[4].innerHTML

            let style2 = index%column === 0 ? 'border-left: 1px solid #f1f1f1' : ''
            let canvas = tds[6].getElementsByTagName('canvas')
            html += '<td style="'+style2+'">'
              html += `<img src="${canvas[0].toDataURL()}" style="width: ${this.card.width*0.4}mm; height: ${this.card.width*0.4}mm"/>`
              if(isTable) html += `<div style="font-size: ${this.card.fontSize}px; text-align: center">${name}</div>`
            html += '</td>'

            let style = index%column === 0 ? 'border-right: 1px solid #f1f1f1;' : 'border-right: 1px solid #f1f1f1;'

            if(!isTable) {
              html += '<td style="'+style+'">'
                html += `<div style="display: flex; flex-direction: column; height: ${this.card.height}mm; justify-content: center; width: ${this.card.width*0.6}mm">`
                  html += `<div style="height: ${this.card.marginTop === 'auto' ? (this.card.height*0.4 - 15) : (this.card.height - this.card.marginTop)}mm; line-height: 1.5">`
                    html += `<div style="font-size: ${this.card.fontSize}px">${id}</div>`
                    html += `<div style="font-size: ${this.card.fontSize}px">${name}</div>`
                    html += `<div>${email}</div>`
                  html += `</div>`
                  html += `<div style="text-align: end"><span style="margin-right: 10px; color: #f1f1f1">ordera.app</span> <img src="https://restorani.bitlab.host/img/o.png" width="30px" style="margin-bottom: -4px; margin-right: 15px;"/></div>`
                html += `</div>`
              html += '</td>'
            }
          }
          if(i === column) html += '</tr>'
          i++
      })
      html += '</table>'

      html = [
        '<!DOCTYPE html>'+
        '<html>',
        '   <head>',
        '   <style>td{border-bottom: 1px solid #f1f1f1;}</style>',
        '   </head>',
        '   <body onload="window.print()" onafterprint="window.close()" style="font-family: Roboto, sans-serif; font-size: '+ (this.card.fontSize - 2) +'px!important;">',
        html,
        '   </body>',
        '</html>'
      ].join('')
      let win = window.open("",
              "",
              "left=0,top=0,toolbar=0,scrollbars=0,status=0"
      );
      win.document.open();
      win.document.write(html);
      win.document.close();

    },
   /*  print(){
      let el = document.getElementById('qrTable');
      let newWin = window.open("");
      newWin.document.write(el.querySelector("table").outerHTML);
      newWin.print();
      newWin.close();
    }, */
    getQR(item) {
      axios.get(`/user/${item.id}/hash`).then(res => {
        return JSON.stringify({id:item.id,secure:res.data});
      })
    },
    initialize() {
      axios
        .get("/companies/" + this.id + "/users")
        .then((res) => {
          this.restaurantName = res.data.name;
          res.data.users.forEach((el, index)=>{
            el.rb= index+1
            this.desserts.push(el);
          })

        });
    },
    initializeRes() {
      axios
          .get("/restaurants/" + this.$route.params.res + "/users")
          .then((res) => {
            this.restaurantName = res.data.name;
            res.data.users.forEach((el, index)=>{
              el.rb= index+1
              this.desserts.push(el);
            })

          });
    },
      initializeJela() {
          axios
              .get("/restaurants/" + this.$route.params.jela + "/foods")
              .then((res) => {
                  this.restaurantName = res.data.name;
                  res.data.foods.forEach((el, index)=>{
                      el.rb= index+1
                      this.desserts.push(el);
                  })

              });
      },



  },
};
</script>

<style>
  .qr-list .v-data-footer {
    padding-right: 115px!important;
  }

  @page {
    size: A3;
    margin: 0;
  }
  @media print {
    html, body {
      width: 210mm;
      height: 297mm;
    }
  }
</style>
